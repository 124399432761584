import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"

import Link from "./LinkWithTransitionLock";
import InViewObserver from "../components/InViewObserver"
// import { FacebookProvider, Page, EmbeddedPost } from 'react-facebook';
import Page_Footer from './page_footer'
// import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class OtherPage extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()
    
  }
 
  componentWillUnmount() {

  }


  render() {

    return (
      <div className="content_wrap other_page">

        <div className="page_header">
          <h2 className="sub_title">路徑</h2>
          <h1 className="main_title">展覽資訊</h1>
          {/* <h6 className="speaker">店主/蔡子晴　筆者/林立青</h6> */}
        </div>

        <div className="page_content">

          <section className="row_area_s other_a"></section>

          <section className="row_area_s other_a">
            <div className="column_4">
              <div className="context">
                <h4>臺北社宅公共藝術線上開展啦！</h4>
                <p>即日起至 5/13 止，限時開展，各項展覽將陸續發表，亦有線上活動可共同參與。敬請鎖定各活動時間，一同探索社宅公共藝術的可能想像。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s other_a">
            <div className="column_4">
              <div className="context">
                <h4>展覽時程</h4>
                <ul className="context_list">
                  <li className="list_item"><div className="date">4/22</div><div className="title">《成為我的繆思，獻給他的禮物》展覽上線</div></li>
                  <li className="list_item"><div className="date">4/28</div><div className="title">《單人部落》展覽上線</div></li>
                  <li className="list_item"><div className="date">4/29</div><div className="title">《駐村編輯》展覽上線</div></li>
                  <li className="list_item"><div className="date">5/4</div><div className="title">《小Q語錄》展覽上線</div></li>
                  <li className="list_item"><div className="date">5/4-8</div><div className="title"> 《蛤？養植物也是公共藝術嗎？》線上實境秀 / 每日更新</div></li>
                </ul>
              </div>
            </div>
            <div className="column_3">
              <div className="context">
                <h4>活動時間</h4>
                <ul className="context_list">
                  <li className="list_item"><div className="date">4/22-5/13</div><div className="title">《行行杯抽獎活動》<a href="https://www.facebook.com/events/229766634943798/" target="_blank">活動辦法↗</a></div><div className="title">《環保袋抽獎活動》<a href="https://www.facebook.com/events/733216240549495/" target="_blank">活動辦法↗</a></div></li>
                  <li className="list_item"><div className="date">4/24-5/13</div><div className="title">《線上文章接力》</div></li>
                  <li className="list_item"><div className="date">4/27</div><div className="title">《孤獨手冊》</div></li>
                  <li className="list_item"><div className="date">5/7</div><div className="title">《媒體座談會線上直播》</div></li>
                </ul>
              </div>
            </div>
          </section>

          <section className="row_area_s other_b row_break">
            <div className="column_4">
              <div className="context">
                <h6 className="subtitle">主辦單位</h6>
                <figure className="figure logo">
                  <img data-src={require("../images/logo/logo_a.png")} alt="臺北市政府都市發展局"/>
                </figure>
              </div>
              <div className="context">
                <h6 className="subtitle">策劃單位</h6>
                <figure className="figure logo">
                  <img data-src={require("../images/logo/logo_b.png")} alt="禾磊藝術有限公司"/>
                </figure>
              </div>
              {/* <div className="context">
                <h6 className="subtitle">聯絡資訊</h6>
                <ul className="context_list">
                  <li><p><strong>策展人</strong>：禾磊藝術 吳慧貞</p></li>
                  <li><p><strong>參展策展人</strong>：沈伯丞、賴映如、林書豪、方彥翔</p></li>
                  <li><p><strong>顧問</strong>：郭旭原、朱弘楠、黃海鳴、胡朝聖、駱麗真、林雋怡、龔書章、殷寶寧、林靜娟、陳碧琳、劉惠媛、褚瑞基、曾光宗</p></li>
                </ul>
              </div> */}
              <div className="context">
                <h6 className="subtitle">策展人</h6>
                <ul className="context_list">
                  <li><p>禾磊藝術 吳慧貞</p></li>
                </ul>
              </div>
              <div className="context">
                <h6 className="subtitle">參展策展人</h6>
                <ul className="context_list">
                  <li><p>沈伯丞、賴映如、林書豪、方彥翔</p></li>
                </ul>
              </div>
              <div className="context">
                <h6 className="subtitle">顧問</h6>
                <ul className="context_list">
                  <li><p>郭旭原、朱弘楠、黃海鳴、胡朝聖、駱麗真、林雋怡、龔書章、殷寶寧、林靜娟、陳碧琳、劉惠媛、褚瑞基、曾光宗</p></li>
                </ul>
              </div>
              <div className="context">
                <ul className="context_list">
                  <li><p><strong>文字編輯</strong>　歐陽辰柔</p></li>
                  <li><p><strong>網站設計</strong>　<a href="https://based.design" target="_blank">BASED DESIGN</a></p></li>
                  <li><p><strong>標誌設計</strong>　Out Of Office</p></li>
                  <li><p><strong>影像創意</strong>　黑鳥音像 BlackBird Imagine Studio</p></li>
                </ul>
              </div>
              <div className="context">
                <h6 className="subtitle">聯絡資訊</h6>
                <ul className="context_list">
                  <li><p>電話: 02-28310487 #15 廖小姐</p></li>
                  <li><p>信箱: artfield28310487@gmail.com</p></li>
                  <li><p>社群: <a href="https://www.facebook.com/HOME-to-ALL-2866082383405643/">Facebook</a>、<a href="https://www.youtube.com/channel/UCQPlOUMsv723HqF45VFG_lQ">Youtube</a></p></li>
                </ul>
              </div>
            </div>
          </section>
          


{/* 
          <section className="row_area_s other_c">
            <div className="column_6">
              <div className="context">
              </div>
            </div>
          </section> */}

        </div>

        <div className="page_footer">
          <Link to="/discourse" className="next_page">
            <div className="next_title">我們也可以這樣生活</div>
            <div className="next_owner">展覽主題</div>
          </Link>
        </div>

      </div>

    )

  }

}


export default OtherPage

